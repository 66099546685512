import React, { useEffect, useRef, } from "react";
import lottie from "lottie-web";
const useLottie = (props, style = undefined) => {
    const { animationData, loop, autoplay, initialSegment, onComplete, onLoopComplete, onEnterFrame, onSegmentStart, onConfigReady, onDataReady, onDataFailed, onLoadedImages, onDOMLoaded, onDestroy, } = props;
    const animationInstanceRef = useRef();
    const animationContainer = useRef(null);
    /*
        ======================================
            INTERACTION METHODS
        ======================================
     */
    /**
     * Play
     * TODO: complete
     */
    const play = () => {
        if (animationInstanceRef.current) {
            animationInstanceRef.current.play();
        }
    };
    /**
     * Stop
     * TODO: complete
     */
    const stop = () => {
        if (animationInstanceRef.current) {
            animationInstanceRef.current.stop();
        }
    };
    /**
     * Pause
     * TODO: complete
     */
    const pause = () => {
        if (animationInstanceRef.current) {
            animationInstanceRef.current.pause();
        }
    };
    /**
     * Set animation speed
     * TODO: complete
     * @param speed
     */
    const setSpeed = (speed) => {
        if (animationInstanceRef.current) {
            animationInstanceRef.current.setSpeed(speed);
        }
    };
    /**
     * Got to frame and play
     * TODO: complete
     * @param value
     * @param isFrame
     */
    const goToAndPlay = (value, isFrame) => {
        if (animationInstanceRef.current) {
            animationInstanceRef.current.goToAndPlay(value, isFrame);
        }
    };
    /**
     * Got to frame and stop
     * TODO: complete
     * @param value
     * @param isFrame
     */
    const goToAndStop = (value, isFrame) => {
        if (animationInstanceRef.current) {
            animationInstanceRef.current.goToAndStop(value, isFrame);
        }
    };
    /**
     * Set animation direction
     * TODO: complete
     * @param direction
     */
    const setDirection = (direction) => {
        if (animationInstanceRef.current) {
            animationInstanceRef.current.setDirection(direction);
        }
    };
    /**
     * Play animation segments
     * TODO: complete
     * @param segment
     * @param force
     */
    const playSegments = (segment, force) => {
        if (animationInstanceRef.current) {
            animationInstanceRef.current.playSegments(segment, force);
        }
    };
    /**
     * Set sub frames
     * TODO: complete
     * @param useSubFrames
     */
    const setSubframe = (useSubFrames) => {
        if (animationInstanceRef.current) {
            animationInstanceRef.current.setSubframe(useSubFrames);
        }
    };
    /**
     * Destroy animation
     * TODO: complete
     */
    const destroy = () => {
        if (animationInstanceRef.current) {
            animationInstanceRef.current.destroy();
        }
    };
    /**
     * Get animation duration
     * TODO: complete
     * @param inFrames
     */
    const getDuration = (inFrames) => {
        if (animationInstanceRef.current) {
            return animationInstanceRef.current.getDuration(inFrames);
        }
        return undefined;
    };
    /*
        ======================================
            LOTTIE
        ======================================
     */
    /**
     * Load a new animation, and if it's the case, destroy the previous one
     * @param {Object} forcedConfigs
     */
    const loadAnimation = (forcedConfigs = {}) => {
        // Return if the container ref is null
        if (!animationContainer.current) {
            return;
        }
        // Destroy any previous instance
        if (animationInstanceRef.current) {
            animationInstanceRef.current.destroy();
        }
        // Build the animation configuration
        const config = {
            ...props,
            ...forcedConfigs,
            container: animationContainer.current,
        };
        // Save the animation instance
        animationInstanceRef.current = lottie.loadAnimation(config);
    };
    /**
     * Initialize and listen for changes that need to reinitialize Lottie
     */
    useEffect(() => {
        loadAnimation();
    }, [animationData, loop, autoplay, initialSegment]);
    /*
        ======================================
            EVENTS
        ======================================
     */
    /**
     * Handle the process of adding an event listener
     * @param {AnimationEventName} eventName
     * @param {AnimationEventHandler} eventHandler
     * @return {Function} Function that deregister the listener
     */
    const addEventListenerHelper = (eventName, eventHandler) => {
        if (animationInstanceRef.current && eventName && eventHandler) {
            animationInstanceRef.current.addEventListener(eventName, eventHandler);
            // Return a function to deregister this listener
            return () => {
                var _a;
                (_a = animationInstanceRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener(eventName, eventHandler);
            };
        }
        return () => { };
    };
    /**
     * Reinitialize listener on change
     */
    useEffect(() => {
        const listeners = [
            { name: "complete", handler: onComplete },
            { name: "loopComplete", handler: onLoopComplete },
            { name: "enterFrame", handler: onEnterFrame },
            { name: "segmentStart", handler: onSegmentStart },
            { name: "config_ready", handler: onConfigReady },
            { name: "data_ready", handler: onDataReady },
            { name: "data_failed", handler: onDataFailed },
            { name: "loaded_images", handler: onLoadedImages },
            { name: "DOMLoaded", handler: onDOMLoaded },
            { name: "destroy", handler: onDestroy },
        ];
        const deregisterList = listeners.map((event) => addEventListenerHelper(event.name, event.handler));
        // Deregister listeners on unmount
        return () => {
            deregisterList.forEach((deregister) => deregister());
        };
    }, [
        onComplete,
        onLoopComplete,
        onEnterFrame,
        onSegmentStart,
        onConfigReady,
        onDataReady,
        onDataFailed,
        onLoadedImages,
        onDOMLoaded,
        onDestroy,
    ]);
    /**
     * Build the animation view
     */
    const View = React.createElement("div", { style: style, ref: animationContainer });
    return {
        View,
        play,
        stop,
        pause,
        setSpeed,
        goToAndStop,
        goToAndPlay,
        setDirection,
        playSegments,
        setSubframe,
        destroy,
        getDuration,
    };
};
export default useLottie;
